<template>
  <div>
    <div style="height:50px">
    </div>
    <v-container fluid >
      <v-layout wrap row>
        <v-flex xs12 sm12 md12 class="pb-3">
          <v-card
              class="pa-2 vCard"
              outlined
          >
          <v-row no-gutters>
            <v-col cols="1">
            </v-col>
            <v-col cols="2" style="border-left : 1px solid #cbcbcb; border-top : 1px solid #cbcbcb; border-bottom: 1px solid #cbcbcb">
            </v-col>
            <v-col cols="3" style=" border-top : 1px solid #cbcbcb; border-bottom: 1px solid #cbcbcb">
              <div style="height: 10px"></div>
              <v-row no-gutters >
                <v-col cols="1">
                </v-col>
                <v-col align-self="stretch" align="center">
                  <v-autocomplete
                      style="margin-top: 0px;"
                      v-model="selectedSampleId"
                      :items="codeList"
                      :menu-props="{ maxHeight: '400' }"
                      :loading = "loading"
                      label="* Sample ID 선택"
                      @change="selectSample"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="1">
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1">
                </v-col>
                <v-col cols="10">

                </v-col>
                <v-col cols="1">
                </v-col>
              </v-row>
            </v-col>
            <v-col cols='3' align-self="stretch" align="center"  style="border-bottom : 1px solid #cbcbcb; border-top : 1px solid #cbcbcb;" >

              <v-row >
                <v-col>
                  <v-btn
                      depressed
                      color="blue-grey"
                      class="ma-2 white--text"
                      height="100px"
                      :loading="loading"
                      :disabled="loading"
                      @click="run"

                  >
                    분석 실행
                  </v-btn>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols='2' align-self="stretch" align="center"  style="border-bottom : 1px solid #cbcbcb; border-top : 1px solid #cbcbcb; border-right: 1px solid #cbcbcb"> <!-- 군집 분석  -->
            </v-col>
            <v-col cols="1">
            </v-col>
          </v-row>
          <v-row no-gutters >
            <v-col cols="1">
            </v-col>
            <v-col cols="1" style="border-bottom: 3px double #cbcbcb">
            </v-col>
            <v-col cols="9" class="searchConditionAreaType1">
              (현재 선택한 조건 확인 및 해당 Sample ID 클릭시 취소가 가능 합니다.)
              <v-chip-group
                  v-model="selection"
                  column
                  active-class="primary--text"
              >
                <draggable v-model="sampleIdList" @start="dragStart" @end="dragEnd">
                  <v-chip v-for="(sampleId, i) in sampleIdList" :key="i" :id="sampleId.name" draggable @click="removeSample" >
                    {{ sampleId.name }}&nbsp;&nbsp;&nbsp;<v-icon small>cancel</v-icon>
                  </v-chip>
                </draggable>
              </v-chip-group>
            </v-col>
            <v-col cols="1">
            </v-col>
          </v-row>
            <v-row v-if="isResult">
              <v-col cols="1">
              </v-col>
              <v-col>
                <v-tabs color="#607D8B" v-model="table" >
                  <v-tab href="#phylum">Phylum</v-tab>
                  <v-tab href="#genus">Phylum-Genus</v-tab>
                  <v-tab href="#species">Genus-Species</v-tab>
                </v-tabs>
              </v-col>
              <v-col align-self="center" align="right" cols="3" style="	font-size: 13px;">
                <v-btn
                    icon
                    color="green"
                    :disabled="isDisableDownloadExcel"
                    @click="downloadExcel"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1">
              </v-col>
            </v-row>
            <v-row v-if="isResult">
              <v-col cols="1">
              </v-col>
              <v-col justify="center" align="center" >
                <v-tabs-items v-model="table">
                  <v-tab-item
                      :value="'phylum'"
                  >
                    <v-card flat>
                      <v-spacer>&nbsp;</v-spacer>
                      <v-spacer>&nbsp;</v-spacer>
                      <v-data-table
                          :headers="headers"
                          :items="searchResult.phylum"
                          item-key="name"
                          hide-default-header
                          :hide-default-footer="true"
                          :disable-pagination="true"
                          :loading = "loading"
                          :search="searchText"
                          loading-text="Loading... Please wait"
                      >
                        <template v-slot:top>
                          <v-text-field
                              v-model="searchText"
                              label="Search Name"
                              class="mx-4"
                          ></v-text-field>
                        </template>
                        <template #header="{ }">
                          <thead class="v-data-table-header">
                          <tr>
                            <th v-for="(h,i) in filteredHeaders " :key="i" class="text-center parent-header td-border-style" :rowspan="h.children?1:2" :colspan="h.children?h.children.length:1">
                              <div class="hover"  :id="h.value" title="phylum" @click="sort">
                                {{h.text}}
                              </div>

                            </th>
                          </tr>
                          <tr>
                            <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1" class="text-center child-header td-border-style" style="height: 35px">
                              <div class="hover" :id="h1.key" @click="sort" >
                                {{ h1.text }}
                              </div>
                            </th>
                          </tr>
                          </thead>
                        </template>
                        <template #item="props">
                          <tr>
                            <td v-for="(c,ci) in getRows(props.item)" :key="ci" :style="{color : props.item.cagrFontColor}" class="text-center">
                              <span v-if="(ci === 'cagr')" >{{ c.value }}</span>
                              <span v-else>{{ c }}</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                      :value="'genus'"
                  >
                    <v-card flat>
                      <v-spacer>&nbsp;</v-spacer>
                      <v-spacer>&nbsp;</v-spacer>
                      <v-data-table
                          :headers="headers"
                          :items="searchResult.genus"
                          item-key="name"
                          hide-default-header
                          :hide-default-footer="true"
                          :disable-pagination="true"
                          :loading = "loading"
                          :search="searchText"
                          loading-text="Loading... Please wait"
                      >
                        <template v-slot:top>
                          <v-text-field
                              v-model="searchText"
                              label="Search Name"
                              class="mx-4"
                          ></v-text-field>
                        </template>
                        <template #header="{ }">
                          <thead class="v-data-table-header">
                          <tr>
                            <th v-for="(h,i) in filteredHeaders " :key="i" class="text-center parent-header td-border-style" :rowspan="h.children?1:2" :colspan="h.children?h.children.length:1">
                              <div class="hover"  :id="h.value" title="phylum" @click="sort">
                                {{h.text}}
                              </div>

                            </th>
                          </tr>
                          <tr>
                            <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1" class="text-center child-header td-border-style" style="height: 35px">
                              <div class="hover" :id="h1.key" @click="sort" >
                                {{ h1.text }}
                              </div>
                            </th>
                          </tr>
                          </thead>
                        </template>
                        <template #item="props">
                          <tr>
                            <td v-for="(c,ci) in getRows(props.item)" :key="ci" :style="{color : props.item.cagrFontColor}" class="text-center">
                              <span v-if="(ci === 'cagr')" >{{ c.value }}</span>
                              <span v-else>{{ c }}</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                      :value="'species'"
                  >
                    <v-card flat>
                      <v-spacer>&nbsp;</v-spacer>
                      <v-spacer>&nbsp;</v-spacer>
                      <v-data-table
                          :headers="headers"
                          :items="searchResult.species"
                          item-key="name"
                          hide-default-header
                          :hide-default-footer="true"
                          :disable-pagination="true"
                          :loading = "loading"
                          :search="searchText"
                          loading-text="Loading... Please wait"
                      >
                        <template v-slot:top>
                          <v-text-field
                              v-model="searchText"
                              label="Search Name"
                              class="mx-4"
                          ></v-text-field>
                        </template>
                        <template #header="{ }">
                          <thead class="v-data-table-header">
                          <tr>
                            <th v-for="(h,i) in filteredHeaders " :key="i" class="text-center parent-header td-border-style" :rowspan="h.children?1:2" :colspan="h.children?h.children.length:1">
                              <div class="hover"  :id="h.value" title="phylum" @click="sort">
                                {{h.text}}
                              </div>

                            </th>
                          </tr>
                          <tr>
                            <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1" class="text-center child-header td-border-style" style="height: 35px">
                              <div class="hover" :id="h1.key" @click="sort" >
                                {{ h1.text }}
                              </div>
                            </th>
                          </tr>
                          </thead>
                        </template>
                        <template #item="props">
                          <tr>
                            <td v-for="(c,ci) in getRows(props.item)" :key="ci" :style="{color : props.item.cagrFontColor}" class="text-center">
                              <span v-if="(ci === 'cagr')" >{{ c.value }}</span>
                              <span v-else>{{ c }}</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col cols="1">
              </v-col>





            </v-row>
          </v-card>
        </v-flex>

      </v-layout>
    </v-container>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import Constants from '../../store/constants'
import XLSX from 'xlsx'

export default {
  name: 'PersonalAnalysis',
  data() {
    return {
      isResult : false, // 결과가 있는지 확인
      selectedSampleId : '',
      selection: null,
      currentTag: null,
      // 비교 분석 하기 위한 Sample ID 들 
      sampleIdList: [],
      headers: [
        {
          text: 'Name',
          align: 'center',
          sortable: false,
          value: 'microbeName',
        },

        {
          text: 'AVG',
          align: 'center',
          value: 'AVG'
        },
        {
          text: 'temp',
          align: 'center',
          value: 'pValue'
        },
        {
          text: 'temp',
          align: 'center',
          value: 'pValue'
        },
        {
          text: '평균증감율',
          sortable: true,
          align: 'center',
          value: 'significantDifference'
        },
      ],
      constants : Constants,
      searchText : "",
      isDisableDownloadExcel: true,
      isDisableLog: true,
      isPvalueSortASC : false,
      table : null,
      loading : false,
      searchResult: {
        phylum : [],
        genus : [],
        species: []
      }
    }

  },
  methods: {
    downloadExcel(){
      if(this.searchResult.phylum.length > 0) {
        let filename = "personal분석.xlsx";
        this.excelExport(filename, this.searchResult)
        .then( () => {
          alert("다운로드가 완료 되었습니다.");
        } )
        .catch( (e)=> {
          console.log(e);
          alert("엑셀 다운로드 실패");
        })
      }else {
        alert("데이터가 없습니다.");
      }
    },
    async excelExport (fileName, resultList) {
      try {
        let byMicrobeList = {
          phylum : [],
          genus : [],
          species : []
        }

        Object.keys(resultList).reduce( (acc, key) => {

          if(key === "orderBySampleIdList") return acc;
          if(key === "isSucc") return acc;

          resultList[key].reduce( (acc, levelObj) => {
            let getData = {};
            getData.microbe = levelObj.microbeName;
            resultList.orderBySampleIdList.reduce((acc, obj)=> {
              getData[obj.code] = levelObj.codeList[obj.code];
            }, 0);
            getData.avg = levelObj.codeListAvg;
            getData['cag(%)'] = levelObj.cagr.value;
            byMicrobeList[key].push(getData);
          }, 0);

        }, "");

        //console.dir(byMicrobeMap);

        let phylumWS = XLSX.utils.json_to_sheet(byMicrobeList.phylum);
        let genusWS = XLSX.utils.json_to_sheet(byMicrobeList.genus);
        let speciesWS = XLSX.utils.json_to_sheet(byMicrobeList.species);

        let wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, phylumWS, 'phylum');
        XLSX.utils.book_append_sheet(wb, genusWS, 'genus');
        XLSX.utils.book_append_sheet(wb, speciesWS, 'species');

        XLSX.writeFile(wb, fileName);

      } catch (e){
        throw new Error(e);
      }

      return true;
    },
    removeSample(e) {
      let removeIndex = -1 ;
      for(let index in this.sampleIdList) {
        if (this.sampleIdList[index].name === e.target.offsetParent.id) {
          removeIndex = index;
        }
      }
      if (removeIndex >= 0) this.sampleIdList.splice(removeIndex, 1);
      this.isResult = false;
      this.isDisableDownloadExcel = true;
    },
    dragStart() {
      alert("start");
      if (this.sampleIdList[this.selection]) this.currentTag = this.sampleIdList[this.selection].name;
      else this.currentTag = null;
    },
    dragEnd() {
      let self = this;
      if (this.currentTag) {
        this.sampleIdList.forEach((x, i) => {
          if (x.name === self.currentTag) self.selection = i;
        });
      }

    },
    ...mapActions(['personalSearch']),
    sort (event) {
      if (this.searchResult.phylum.length <= 0 && this.searchResult.genus.length <= 0
          && this.searchResult.species.length <= 0) return;

      let targetAttr = event.target.name || event.target.id;
      let targetName = "";
      let isASC;
      switch (targetAttr) {
        case "microbeName" :
        case "Name" :
          targetName = "microbeName";
          break;
        default :
          targetName = targetAttr;
      }

      isASC = this.isPvalueSortASC;
      this.isPvalueSortASC = !this.isPvalueSortASC;

      this.searchResult.phylum.sort(sortFunc);
      this.searchResult.genus.sort(sortFunc);
      this.searchResult.species.sort(sortFunc);

      function sortFunc(a, b) {
        switch(targetName) {
          case 'microbeName' :
            return (isASC) ? (a[targetName] < b[targetName] ? -1 : a[targetName] > b[targetName] ? 1 : 0) :
                (a[targetName] > b[targetName] ? -1 : a[targetName] < b[targetName] ? 1 : 0);
          case 'cagr':
            return (isASC) ? a[targetName].value - b[targetName].value : b[targetName].value - a[targetName].value;
          case 'codeListAvg':
            return (isASC) ? a[targetName] - b[targetName] : b[targetName] - a[targetName];
          default :
            return (isASC) ? a.codeList[targetName] - b.codeList[targetName] : b.codeList[targetName] - a.codeList[targetName];
        }
      }

    },
    //군집 분석 실행
    run : function() {
      this.isResult = false;
      this.loading = !this.loading;
      this.search().then( ret => {
        if(!ret) {
          this.abnormalProcess();
          return;
        }
        //setTimeout( ()=> { this.loading = !this.loading }, 3000);
      }).catch( e=> {
        console.log(e);
        this.abnormalProcess();
      })

    },
    abnormalProcess : function () {
      this.loading = false;
      this.isResult = false;
      this.isDisableDownloadExcel = false;
    },
    getSubHeader(headers) {
      let result = [];
      headers.
          filter(i => i.children).
          forEach(v => {
            result = result.concat(v.children);
          });
      return result;
    },
    toExponential(numberData) {
      return numberData.toExponential(1) || 0;
    },
    toPercent(numberData) {
      if(numberData === 0) return 0;
      return (numberData * 100).toFixed(2) || 0;
    },
    // table rows 데이터 처리
    getRows(rows) {

      //row 에서 순서대로 출력하기 때문에 순서 주의
      let rowDataObj = {
        microbeName : rows.microbeName,
      };

      // 현재 순서로 오브젝트 생성 후 초기화
      for(let i =0 ; i < this.sampleIdList.length ; i++){
        rowDataObj[this.sampleIdList[i].name] = 0;
      }

      // 결과 데이터 대입
      Object.keys(rows.codeList).reduce( (acc, key) => {
        rowDataObj[key] = this.toPercent(rows.codeList[key]);
      }, '');

      rowDataObj.codeListAvg = this.toPercent(rows.codeListAvg);
      rowDataObj.cagr = rows.cagr;
      //상위 엘리먼트에서 rows 전체 색을 표현 하기 위해 처리
      rows.cagrFontColor = rowDataObj.cagr.cagrFontColor;

      //console.log("rowDataObj");
      //console.dir(rowDataObj);
      return rowDataObj;
    },
    // Sample 선택
    selectSample : function() {
      for(let sampleIdObj of this.sampleIdList) {
        if (this.selectedSampleId === sampleIdObj.name) {
          alert("같은 Sample ID 는 선택 할 수 없습니다.");
          return;
        }
      }

      if (this.sampleIdList.length >= 5) {
        alert("최대 5개 이상 선택 할 수 없습니다.");
        return;
      }

      this.sampleIdList.push({ name: this.selectedSampleId } );
      this.selectedSampleId = {}
      this.isResult = false;
    },
    changeHeaderBySampleIds : function() {
      let headers = [];
      headers.push(this.getFirstHeader());

      for(let i =0 ; i < this.sampleIdList.length ; i++){
        headers.push(this.getSampleHeader(this.sampleIdList[i].name));
      }

      headers = [...headers, ...this.getTailHeaders()];
      this.headers = headers;
      console.dir(this.headers)
    },
    getFirstHeader : function() {
      return {
        text: 'Name',
        align: 'center',
        sortable: false,
        value: 'microbeName',
      }
    },
    getSampleHeader : function(sampleId) {
      return {
        text: sampleId,
        align: 'center',
        sortable: false,
        value: sampleId,
      }
    },
    getTailHeaders : function() {
      return [
        {
          text: 'AVG',
          align: 'center',
          sortable: false,
          value: 'codeListAvg',
        },
        {
          text: '평균증감율',
          align: 'center',
          sortable: false,
          value: 'cagr',
        }
      ]

    },
    loadingToggle : function() {
      this.loading = !this.loading;
    },
    validSearch : function() {
      if (this.sampleIdList.length <= 0 ) {
        alert("선택된 Sample ID가 없습니다.");
        return false;
      }
      return true;
    },
    search : async function(){

      if(!this.validSearch()) return;

      let searchData = {
        sampleIdList : this.getChangeSampleIdList(this.sampleIdList)
      }

      let searchResult = await this.personalSearch(searchData);
      this.changeSearchValue(searchResult, false);
      // Sampleid List 순서에 따라 header 컬럼 재 생성
      this.changeHeaderBySampleIds();
      this.searchResult = searchResult;
      this.isPvalueSortASC = false;
      this.sort({
        target : {
          name: 'codeListAvg'
        }
      });

      this.loading = false;
      this.isResult = true;
      this.isDisableDownloadExcel = false;
      return true;
    },
    getChangeSampleIdList : function (sampleIdList) {
      let changeSampleIdList = [];
      for(let sampleObj of sampleIdList) {
        changeSampleIdList.push(sampleObj.name);
      }

      return changeSampleIdList;
    },
    changeSearchValue (searchResult = {}) {
      //header 순서 변경
      if (searchResult.orderBySampleIdList === undefined) return;
      this.sampleIdList = [];
      for (let sampleIdObj of searchResult.orderBySampleIdList) {
        this.sampleIdList.push({ name : sampleIdObj.code })
      }
    }
  },
  mounted() {
  },
  created () {
  },
  computed : {
    codeList : {
      get () {
        return this.$store.state.codeList
      }
    },
    // header slot 처리 했기 때문에 ...  slot 에서는 정상적인 row 가 필요, headers 에서는 서브 해더를 모르기 때문에  row 수를 임의로 추가 하여 처리 (해더는 서브 해더 수 만큼 필요함)
    filteredHeaders () {
      return this.headers.filter(item => item.text !== 'temp');
    }
  }

}
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}
.vCard {
 border: 1px solid white;
}
.hover {
  cursor : pointer !important;
}
.hover:hover {
  color: #008EDC;
}
.searchConditionAreaType1 {
  border-bottom: 3px double #cbcbcb;
  height: 100px;
  font-size: 13px;
}
.searchConditionAreaType2 {
  border-bottom: 3px double #cbcbcb;
  height: 220px;
  font-size: 13px;
}
.buttonFocusIn {
  background-color: #5885AF !important;
  color: #ffffff;
  cursor: pointer;
  outline: none;
}
.buttonFocusOut {
  background-color: #f1f0f0 !important;
  cursor: pointer;
  outline: none;
}
.tdSpan { color : #d52136
}
.anovaTable{
  font-size: 13px;
  border-style: hidden;
  border-collapse: collapse;
}
.anovaTd{
  padding: 5.5px;
  margin: 0;
  width:10px;border-style: hidden;
  text-align: left;
  vertical-align: middle;
}
.inputAge{
  width:45px;height:35px;border: solid 1px;text-align:center;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.spanTitle{
  font-size: 13px !important;
  color: #747883;
}
/*.v-data-table::v-deep td {
  font-size: 12px !important;
}*/
.v-text-field >>> label {
  font-size: 13px;
}
.tooltipTitle { color: yellow }
</style>

